import React, { lazy, Suspense } from "react";
import "./App.css";
import { Container, ThemeProvider } from "@mui/material";
import Loading from "./sections/Loading/Loading";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import { theme } from "./constants/Theme";

const Header = lazy(() => import("./sections/Header/Header"));
const ScheduleAndMap = lazy(
  () => import("./sections/ScheduleAndMap/ScheduleAndMap")
);
const Game = lazy(() => import("./sections/Game/Game"));
const DUK = lazy(() => import("./sections/DUK/DUK"));
const ImageGallery = lazy(() => import("./sections/ImageGallery/ImageGallery"));
const Footer = lazy(() => import("./sections/Footer/Footer"));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MotionLazyContainer>
        <Container maxWidth="xl" id="app">
          <Suspense fallback={<Loading />}>
            <section>
              <Header />
              <ScheduleAndMap />
              <DUK />
              <Game />
              <ImageGallery />
              <Footer />
            </section>
          </Suspense>
        </Container>
      </MotionLazyContainer>
    </ThemeProvider>
  );
}

export default App;
