import { Box } from "@mui/material";

export default function Loading() {
  const Logo = require("../../assets/monkalas-logo.png");

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ maxWidth: "100vw", height: "100vh" }}
    >
      <img alt="Monkalas logo" src={Logo} />
    </Box>
  );
}
